import React from "react";
import CoursesC from "../components/CoursesCkids";
import "./Shop.css";
import { Helmet } from "react-helmet";

const courses = () => {
  return (
    <div className="shoppage">
      <Helmet>
        <title>courses</title>
      </Helmet>
      <CoursesC />
    </div>
  );
};

export default courses;
