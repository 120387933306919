import React, { useEffect, useState, useRef } from "react";
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";
import { Link } from "react-router-dom";
import LearnNowBtn from "./learnNowBtn";

const KidsSliderData = [
  {
    title: "Fun Learning",
    subtitle: "Interactive Courses Just for Kids",
  },
  {
    title: "Play & Learn",
    subtitle: "Combine Fun and Education",
  },
  {
    title: "Creative Minds",
    subtitle: "Nurture Creativity in Young Learners",
  },
  {
    title: "Back to School",
    subtitle: "Prepare Your Kids for the School Year",
  },
];

const KidsSlider = () => {
  const [current, setCurrent] = useState(0);
  const length = KidsSliderData.length;
  const intervalTime = 12000;
  const slideInterval = useRef(null);

  const nextSlide = () => {
    setCurrent((prev) => (prev === length - 1 ? 0 : prev + 1));
  };

  const prevSlide = () => {
    setCurrent((prev) => (prev === 0 ? length - 1 : prev - 1));
  };

  useEffect(() => {
    slideInterval.current = setInterval(nextSlide, intervalTime);

    return () => {
      clearInterval(slideInterval.current);
    };
  }, [intervalTime]);

  const handleNext = () => {
    clearInterval(slideInterval.current);
    nextSlide();
    slideInterval.current = setInterval(nextSlide, intervalTime);
  };

  const handlePrev = () => {
    clearInterval(slideInterval.current);
    prevSlide();
    slideInterval.current = setInterval(nextSlide, intervalTime);
  };

  return (
    <div className="slider kids">
      {KidsSliderData.map((slide, index) => (
        <div
          key={index}
          className={index === current ? "slide current" : "slide"}
        >
          <h1 className="titleslider">{slide.title}</h1>
          <h3 className="subtitleslider">{slide.subtitle}</h3>
          <div className="content">
            <Link to="/courseskids">
              <LearnNowBtn />
            </Link>
          </div>
        </div>
      ))}
      <IoIosArrowForward className="next" size="40" onClick={handleNext} />
      <IoIosArrowBack className="prev" size="40" onClick={handlePrev} />
    </div>
  );
};

export default KidsSlider;
