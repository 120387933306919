import React,{useRef,useEffect, useState} from 'react'
import { Helmet } from 'react-helmet';
import {Image} from '@chakra-ui/react'
import HashLoader from "react-spinners/HashLoader";
import './aboutcss.css'
const About = () => {
    const Line = useRef(null);
    const text = useRef(null);
    useEffect(() => {
                setTimeout(() =>{
        Line.current.classList.add('lineon')
        text.current.classList.add('titleon');
        },5)


        return () => {

        }
    },[])
    return (
        

        
        <>
        <Helmet>
            <title>About</title>
        </Helmet>


            <div className='headingA'>
                <div className = 'line' ref={Line}>
                </div>
                <h1 className ='title' ref={text}>About Us</h1>
            </div>
            <div className='Content1'>
                <div className = 'text'>
                    <h1>
                        Why?
                    </h1>
                <p>
                    
                    At Patic Center of Formation, we believe in the transformative power of quality education and specialized training. Our commitment to excellence and innovation sets us apart, providing you with a learning environment that fosters both personal and professional growth. We chose Patic Center of Formation because of its proven track record in producing skilled, competent professionals who excel in their respective fields. Our dedicated team of experienced instructors, cutting-edge resources, and comprehensive curriculum are designed to equip you with the knowledge and skills needed to thrive in today’s competitive landscape. Join us at Patic Center of Formation and take the first step towards a brighter, more successful future.</p>
                </div>
               
                   <div className ='imagecontainer'>
                    <div className = 'Imageabt'>
                    <Image className='mImage' boxSize = '400px' objectFit="cover" src='https://i.postimg.cc/85rsh5wF/patic-about-us-1.png' alt="Patic-1"/>
                    </div>
                   </div>
            </div>
            <div className ='Content2'>
                   
            <div className='imagecontainer'>
            <div className='Imageabt' style={{ position: 'relative', width: '400px', height: '400px' }}>
                <iframe 
                    allow="fullscreen;autoplay" 
                    allowFullScreen 
                    src="https://streamable.com/e/gtitdm?autoplay=1&nocontrols=1" 
                    style={{ border: 'none', width: '100%', height: '100%', position: 'absolute', left: '0px', top: '0px', overflow: 'hidden' }}
                ></iframe>
            </div>
        </div>
                   <div className = 'text'>
                    <h1>
                        How?
                    </h1>
                <p>At Patic Center of Formation, we achieve excellence through a combination of rigorous academic standards, hands-on training, and a supportive learning environment. Our curriculum is meticulously crafted to meet industry demands, ensuring that each course is relevant and up-to-date. We employ experienced instructors who bring real-world expertise into the classroom, enhancing the learning experience with practical insights and mentorship. Utilizing state-of-the-art facilities and resources, we provide a dynamic and interactive education that prepares our students for the challenges of their chosen careers. Furthermore, our personalized approach to education means that each student receives the attention and support they need to succeed. Through continuous evaluation and feedback, we ensure that our programs evolve to meet the ever-changing needs of the industry, making Patic Center of Formation a leader in vocational and professional training.</p>
                </div>
            </div>
        </>
    
    )
}

export default About
