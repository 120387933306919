import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import axios from "axios";

const VerificationSuccess = () => {
  const { token } = useParams();
  const [message, setMessage] = useState("");
  const history = useHistory();

  useEffect(() => {
    const verifyToken = async () => {
      try {
        const { data } = await axios.get(`/api/users/verify/${token}`);
        setMessage(data.message);
        setTimeout(() => {
          history.push("/login");
        }, 3000); // Redirect after 3 seconds
      } catch (error) {
        setMessage("Verification failed. Please try again.");
      }
    };

    verifyToken();
  }, [token, history]);

  return (
    <div>
      <h1>{message}</h1>
    </div>
  );
};

export default VerificationSuccess;
