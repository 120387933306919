import Nav from "./components/Nav";
import Home from "./pages/Home";
import About from "./pages/About/About";
import courses from "./pages/courses";
import courseskids from "./pages/courseskids";
import Contactus from "./pages/Contactus/Contactus";
import Coursepage from "./pages/Course/Coursepage";
import Cartpage from "./pages/Cart/Cartpage";
import Footer from "./pages/Footer/Footer";
import LoginScreen from "./pages/Login/LoginScreen";
import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/react";
import ScrollIntoView from "./components/Scrollintoview";
import HashLoader from "react-spinners/HashLoader";
import RegisterScreen from "./components/RegisterScreen";
import ForgotPasswordScreen from "./components/forgotScreen";
import ResetPasswordScreen from "./components/ResetpswdScreen";
import ProfileScreen from "./components/ProfileScreen";
import Checkout from "./pages/checkout/Checkout";
import Placeorder from "./pages/placeorder/Placeorder";
import Order from "./pages/Order/Order";
import Users from "./pages/Userslist/Users";
import NotFoundPage from "./components/Notfoundpage";
import Edituser from "./pages/Useredit/Edituser";
import Courses from "./pages/courses/courses";
import EditCourse from "./pages/Editcourse/Editcourse";
import Orders from "./pages/Orders/Orders";
import HomeKid from "./pages/Homekid";
import VerificationSuccess from "./components/VerificationSuccess";
import ProtectedRoute from "./components/protectRoutes";
import CourseRedirect from "./components/CourseRedirect";

const App = () => {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  }, []);

  return (
    <div className="main">
      <ChakraProvider>
        <Router>
          <ScrollIntoView>
            {loading ? (
              <div className="loading">
                <HashLoader color={"#1e1e2c"} loading={loading} size={40} />
              </div>
            ) : (
              <>
                <Nav />
                <Switch>
                  <ProtectedRoute path="/" exact component={Home} />
                  <Route path="/about" component={About} />
                  <CourseRedirect path="/courses" component={courses} />
                  <Route path="/contactus" component={Contactus} />
                  <Route path="/course/:id" component={Coursepage} />
                  <Route path="/cart/:id?" component={Cartpage} />
                  <Route path="/login" component={LoginScreen} />
                  <Route path="/register" component={RegisterScreen} />
                  <Route path="/forgot" component={ForgotPasswordScreen} />
                  <Route
                    path="/reset-password"
                    component={ResetPasswordScreen}
                  />
                  <Route path="/profile" component={ProfileScreen} />
                  <Route path="/shipping" component={Checkout} />
                  <Route path="/placeorder" component={Placeorder} />
                  <Route path="/order/:id" component={Order} />
                  <Route path="/admin/userlist" component={Users} />
                  <Route path="/admin/courselist" component={Courses} />
                  <Route path="/admin/orderlist" component={Orders} />
                  <Route path="/search/:keyword" component={courses} />
                  <Route path="/kids" component={HomeKid} />
                  <Route path="/courseskids" component={courseskids} />
                  <Route path="/admin/user/:id/edit" component={Edituser} />
                  <Route path="/admin/course/:id/edit" component={EditCourse} />
                  <Route
                    path="/verify/:token"
                    component={VerificationSuccess}
                  />
                  <Route component={NotFoundPage} />
                </Switch>
                <Footer />
              </>
            )}
          </ScrollIntoView>
        </Router>
      </ChakraProvider>
    </div>
  );
};
export default App;
