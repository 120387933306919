import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";

const CourseRedirect = ({ component: Component, ...rest }) => {
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  return (
    <Route
      {...rest}
      render={(props) =>
        userInfo && userInfo.isKid ? (
          <Redirect to="/courseskids" />
        ) : (
          <Component {...props} />
        )
      }
    />
  );
};

export default CourseRedirect;
