import React from "react";
import Slider from "../components/Sliderkids";
import CardscgKids from "../components/Cardscgkids";
import CgDiv from "../components/CgDivkids";
import CoursesC from "../components/CoursesCkids";
import { Helmet } from "react-helmet";

const HomeKids = () => {
  return (
    <>
      <Helmet>
        <title>Patic</title>
      </Helmet>
      <div>
        <Slider />
        <div className="cards">
          <CardscgKids title="Programming" />
          <CardscgKids title="Language" />
          <CardscgKids title="Soft Skills" />
        </div>
        <CgDiv />
        <CoursesC />
      </div>
    </>
  );
};

export default HomeKids;
