import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CardCourse from "./CardCoursekids";
import {
  listkidsCourses,
  ListcoursebyCg,
  Listcoursebyfiter,
  Listcoursebyprice,
} from "../actions/courseActions";
import { BsFilter } from "react-icons/bs";
import { AiOutlineSearch } from "react-icons/ai";
import { IoMdClose } from "react-icons/io";

import Search from "./Search";
import {
  NumberInput,
  NumberInputField,
  FormLabel,
  Button,
  Stack,
  FormControl,
} from "@chakra-ui/react";
import HashLoader from "react-spinners/HashLoader";
import { Link, Route } from "react-router-dom";
const CoursesC = ({ match, history }) => {
  const [From, setFrom] = useState(0);
  const [To, setTo] = useState(0);

  let Cg = window.location.search ? window.location.search.split("=")[1] : null;
  const keyword = window.location.pathname.split("/")[2];
  const dispatch = useDispatch();
  const courseList = useSelector((state) => state.courseList);
  const coursebycg = useSelector((state) => {
    return state.ListcoursebyCg;
  });
  const coursebyfilter = useSelector((state) => {
    return state.Listcoursebyfilter;
  });
  const coursebyprice = useSelector((state) => {
    return state.Listcoursebyprice;
  });

  const { loading, error, courses } = coursebycg
    ? coursebycg
    : courseList
    ? courseList
    : coursebyprice;
  useEffect(() => {
    if (Cg) {
      console.log(window.location.search.split("=")[0]);
      if (window.location.search.split("=")[0] === "?cg") {
        dispatch(ListcoursebyCg(Cg));
        console.log(courses);
      } else {
        dispatch(Listcoursebyfiter(Cg));
      }
    } else {
      dispatch(listkidsCourses(keyword));
    }
  }, [dispatch, Cg, keyword]);
  const [showfilter, setshowfilter] = useState(false);
  const [showsearch, setshowsearch] = useState(false);
  const filterfunc = () => {
    setshowfilter(!showfilter);
    if (showsearch) {
      setshowsearch(false);
    }
  };
  const searchfunc = () => {
    setshowsearch(!showsearch);
    if (showfilter) {
      setshowfilter(false);
    }
  };
  const pricehandler = () => {
    dispatch(Listcoursebyprice(From, To));
  };

  return (
    <>
      <div className="Cgfilter">
        <h1>
          {Cg ? Cg : keyword ? "*" + keyword + "* Search" : ""} Courses
        </h1>
        <div className="filtersbtn ">
          <button
            className={`filterbtn ${showfilter ? "activebtn" : ""}`}
            onClick={filterfunc}
          >
            {" "}
            {showfilter ? <IoMdClose size="20" /> : <BsFilter size="20" />}
            Filter
          </button>

          <button
            className={`searchbtn ${showsearch ? "activebtn" : ""}`}
            onClick={searchfunc}
          >
            {showsearch ? (
              <IoMdClose size="20" />
            ) : (
              <AiOutlineSearch size="20" />
            )}
            Search
          </button>
        </div>

        <div className="filters">
          <ul>
            <Link className="lined" to="?cg=LanguageKids">
              Language
            </Link>
            <Link className="lined" to="?cg=ProgrammingKids">
              Programming
            </Link>
            <Link className="lined" to="?cg=Softskills">
              Soft skills
            </Link>
          </ul>
        </div>
      </div>
      {showsearch && (
        <Route render={({ history }) => <Search history={history} />} />
      )}
      <div className={`filterarea ${showfilter ? "filter" : "filteroff"}`}>
        <div className="sortbydiv">
          <h1> Sort By</h1>
          <ul>
            <Link
              onClick={() => setshowfilter(false)}
              className="lined"
              to="?filter=highprice"
            >
              Default
            </Link>
            <Link
              onClick={() => setshowfilter(false)}
              className="lined"
              to="?filter=Rating"
            >
              Rating
            </Link>
            <Link
              onClick={() => setshowfilter(false)}
              className="lined"
              to="?filter=date"
            >
              Date
            </Link>
            <Link
              onClick={() => setshowfilter(false)}
              className="lined"
              to="?filter=highprice"
            >
              Low to high price
            </Link>
            <Link
              onClick={() => setshowfilter(false)}
              className="lined"
              to="?filter=lowprice"
            >
              high to low price
            </Link>
          </ul>
        </div>
        <div className="pricediv">
          <h1> Price</h1>
          <FormControl id="email">
            <Stack spacing={2}>
              <FormLabel>From :</FormLabel>
              <NumberInput
                value={From}
                bg="white"
                onChange={(e) => setFrom(e)}
                borderRadius="md"
                borderTopRadius="md"
                borderTopLeftRadius="md"
              >
                <NumberInputField />
              </NumberInput>
              <FormLabel>To :</FormLabel>
              <NumberInput
                value={To}
                bg="white"
                onChange={(e) => setTo(e)}
                borderRadius="md"
                borderTopRadius="md"
                borderTopLeftRadius="md"
              >
                <NumberInputField />
              </NumberInput>
              <Button onClick={pricehandler} type="submit" colorScheme="teal">
                Filter
              </Button>
            </Stack>
          </FormControl>
        </div>
      </div>
      {loading ? (
        <div className="loading">
          <HashLoader color={"#fff"} loading={loading} size={40} />
        </div>
      ) : error ? (
        <h2>{error} </h2>
      ) : courses.length === 0 ? (
        <h1 className="nothingfound">Nothing Found !!!</h1>
      ) : (
        <div className="cardsCourse">
          {courses.map((course) => (
            <CardCourse key={course._id} course={course} />
          ))}
        </div>
      )}
    </>
  );
};

export default CoursesC;
