import React from 'react';
import { Img } from '@chakra-ui/react';
import './Notfound.css';

const NotFoundPage = () => {
  return (
    <div className="not-found-container">
      <h1>404 Not Found</h1>
      <Img 
        src="https://i.postimg.cc/qBj607Q2/outline-lettering-error-404-with-warning-sign-and-wrench-text.png" 
        alt="Not Found" 
        className="not-found-image"
      />
    </div>
  );
};

export default NotFoundPage;
