import React, { useEffect, useState, useRef } from "react";
import Rating from "../../components/Rating";
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet";

import {
  listCourseDetails,
  createcourseReview,
} from "../../actions/courseActions";
import { IoLogoFacebook } from "react-icons/io5";
import {
  AiFillTwitterCircle,
  AiFillInstagram,
  AiFillShop,
} from "react-icons/ai";
import { MdDoNotDisturb } from "react-icons/md";

import {
  Image,
  Select,
  Button,
  FormControl,
  FormLabel,
  Textarea,
} from "@chakra-ui/react";
import HashLoader from "react-spinners/HashLoader";
import {
  COURSE_CREATE_RESET,
  COURSE_CREATE_REVIEW_RESET,
} from "../../constants/courseConstants";
import "./course.css";
import { Link } from "react-router-dom";
const Coursepage = ({ history, match }) => {
  const [qty, setQty] = useState(1);
  const [rating, setrating] = useState(0);
  const [comment, setcomment] = useState("");

  const imgs = document.querySelectorAll(".img-select a");
  const imgShowcase = useRef(null);
  const imgBtns = [...imgs];
  let imgId = 1;
  const dispatch = useDispatch();
  const courseDetails = useSelector((state) => state.courseDetails);
  const { loading, error, course } = courseDetails;
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const courseReviewCreate = useSelector((state) => state.courseReviewCreate);
  const { success: successCourseReview, error: errorCourseReview } =
    courseReviewCreate;

  imgBtns.forEach((imgItem) => {
    imgItem.addEventListener("click", (event) => {
      event.preventDefault();
      imgId = imgItem.dataset.id;
      slideImage();
    });
  });

  function slideImage() {
    const displayWidth = document.querySelector(
      ".img-showcase img:first-child"
    ).clientWidth;
    imgShowcase.current.style.transform = `translateX(${
      -(imgId - 1) * displayWidth
    }px)`;
  }

  useEffect(() => {
    if (successCourseReview) {
      alert("Review Submitted!");
      setrating(0);
      setcomment("");
      dispatch({ type: COURSE_CREATE_REVIEW_RESET });
    }
    dispatch(listCourseDetails(match.params.id));
  }, [dispatch, match, successCourseReview]);

  const submithanlder = () => {
    dispatch(
      createcourseReview(match.params.id, {
        rating,
        comment,
      })
    );
  };
  //Handler of button add to cart
  const addToCartHandler = () => {
    history.push(`/cart/${match.params.id}?qty=${qty}`);
  };
  return (
    <>
      <Helmet>
        <title>{course.name}</title>
      </Helmet>
      <div className="coursepage">
        {loading ? (
          <div className="loading-course">
            <HashLoader color={"#1e1e2c"} loading={loading} size={50} />
          </div>
        ) : error ? (
          <h2>{error} </h2>
        ) : (
          <div className="card-wrapper">
            <div className="card">
              <div className="course-imgs">
                <div className="img-display">
                  <div ref={imgShowcase} className="img-showcase">
                    {course.images.map((i) => (
                      <Image src={i} />
                    ))}
                  </div>
                </div>
                <div className="img-select">
                  <div className="img-item">
                    <a href="#" data-id="1">
                      <Image
                        objectFit="cover"
                        boxSize="200px"
                        src={course.images[0]}
                        alt="shoe image"
                      />
                    </a>
                  </div>
                  <div className="img-item">
                    <a href="#" data-id="2">
                      <Image
                        objectFit="cover"
                        boxSize="200px"
                        src={course.images[1]}
                        alt="shoe image"
                      />
                    </a>
                  </div>
                  <div className="img-item">
                    <a href="#" data-id="3">
                      <Image
                        objectFit="cover"
                        boxSize="200px"
                        src={course.images[2]}
                        alt="shoe image"
                      />
                    </a>
                  </div>
                </div>
              </div>

              <div className="course-content">
                <h2 className="course-title">{course.name} </h2>
                <Link to="/courses" className="course-link">
                  visit our store
                </Link>
                <Rating
                  value={course.rating}
                  text={`${course.numReviews} reviews`}
                />
                <div className="course-price">
                  {/* <p className="last-price">
                    Old Price: <span>Tnd{course.price + course.price * 0.5}</span>
                  </p> */}
                  <p className="new-price">
                   Price: <span>{course.price} Tnd</span>
                  </p>
                </div>

                <div className="course-detail">
                  <h2>about this Course: </h2>
                  <p>{course.description}</p>
                  
                  <ul>
                    <li>
                      Status:{" "}
                      <span>
                        {course.availability > 0 ? "available" : "Sold Out"}
                      </span>
                    </li>
                    <li>
                      Category:{" "}
                      <span>
                        {course.category.map((cg) => " | " + cg + " | ")}
                      </span>
                    </li>

                    <div>
                      <ul>
                        {" "}
                        <li>Qty :</li>
                        {course.availability > 0 ? (
                          <Select
                            as="select"
                            size="md"
                            maxW={20}
                            value={qty}
                            className="select-course"
                            onChange={(e) => setQty(e.target.value)}
                          >
                            {[...Array(course.availability).keys()].map((x) => (
                              <option key={x + 1} value={x + 1}>
                                {x + 1}
                              </option>
                            ))}
                          </Select>
                        ) : (
                          <span style={{ display: "flex" }}>
                            <MdDoNotDisturb size="26" /> Sold Out{" "}
                          </span>
                        )}
                      </ul>
                    </div>
                  </ul>
                </div>

                <div className="purchase-info">
                  <Button
                    onClick={addToCartHandler}
                    type="button"
                    className="btn-courses"
                    disabled={course.availability === 0}
                  >
                    {" "}
                    <AiFillShop size="24" />
                    Add to Cart{" "}
                  </Button>
                </div>

                <div className="social-links">
                  <p>Share On: </p>
                  <a className="social" href="https://www.facebook.com/PATIC.2017" target="_blank" rel="noopener noreferrer">
                    <i>
                      <IoLogoFacebook size="20" />
                    </i>
                  </a>
                  <a className="social" href="https://twitter.com/" target="_blank" rel="noopener noreferrer">
                    <i>
                      <AiFillTwitterCircle size="20" />
                    </i>
                  </a>
                  <a className="social" href="https://www.instagram.com/patic.international" target="_blank" rel="noopener noreferrer">
                    <i>
                      <AiFillInstagram size="20" />
                    </i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="REVIEWS">
          <h1>Reviews :</h1>
          {course.reviews.length === 0 && <h2>NO REVIEWS</h2>}
          <div>
            {course.reviews.map((review) => (
              <div className="review">
                <h4>{review.name}</h4>
                <div className="Ratingreview">
                  <Rating value={review.rating} />
                </div>
                <p className="commentreview">{review.comment}</p>
                <p className="datereview">
                  {review.createdAt.substring(0, 10)}
                </p>
              </div>
            ))}
            <div className="createreview">
              <h1>Create New Review :</h1>
              {errorCourseReview && <h2>{errorCourseReview}</h2>}
              {userInfo ? (
                <FormControl>
                  <FormLabel>Rating :</FormLabel>
                  <Select onChange={(e) => setrating(e.target.value)}>
                    <option value="1">1 POOR</option>
                    <option value="2">2 FAIR</option>
                    <option value="3">3 GOOD</option>
                    <option value="4">4 VERY GOOD</option>
                    <option value="5">5 EXCELLENT</option>
                  </Select>
                  <FormLabel>Comment :</FormLabel>
                  <Textarea
                    onChange={(e) => setcomment(e.target.value)}
                    placeholder="Leave Comment here :"
                  />
                  <Button colorScheme="blue" onClick={submithanlder}>
                    Submit
                  </Button>
                </FormControl>
              ) : (
                <>
                  Please <Link to="/login">Sign In</Link> To write a review.
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Coursepage;
