import React from 'react'

const LearnNowBtn = () => {
    return (
            <button className="learnNow">
                Learn Now
                </button>
        
    )
}

export default LearnNowBtn
