import React, { useState, useEffect } from "react";
import axios from "../../axios";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import { useDispatch, useSelector } from "react-redux";
import { IoMdDoneAll } from "react-icons/io";

import HashLoader from "react-spinners/HashLoader";
import {
  getOrderDetails,
  payOrder,
  learnOrder,
} from "../../actions/orderActions";
import "./Order.css";
import {
  ORDER_PAY_RESET,
  ORDER_LEARN_RESET,
} from "../../constants/orderConstants";
import { Button } from "@chakra-ui/button";
const Order = ({ match, history }) => {
  const [sdkReady, setsdkReady] = useState(false);
  const orderId = match.params.id;
  const dispatch = useDispatch();
  const orderDetails = useSelector((state) => state.orderDetails);
  const { order, loading, error } = orderDetails;
  const orderPay = useSelector((state) => state.orderPay);
  const { loading: loadingpay, success: successPay } = orderPay;

  const orderLearn = useSelector((state) => state.orderLearn);
  const { loading: loadinglearn, success: successlearn } = orderLearn;
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const addDecimals = (num) => {
    return (Math.round(num * 100) / 100).toFixed(2);
  };
  if (!loading) {
    order.itemsPrice = addDecimals(
      order.orderItems.reduce((acc, item) => acc + item.price * item.qty, 0)
    );
  }
  useEffect(() => {
    if (!userInfo) {
      history.push("/login");
    }
 
    if (!order || successPay || successlearn || order._id !== orderId) {
      dispatch({
        type: ORDER_PAY_RESET,
      });
      dispatch({
        type: ORDER_LEARN_RESET,
      });
      dispatch(getOrderDetails(orderId));
    } 
    }
  , [dispatch, orderId, successPay, orderPay, successlearn, userInfo]);
  const successpaymenthandler = (paymentResult) => {
    dispatch(payOrder(orderId, paymentResult));
  };
  const learnhandler = () => {
    dispatch(learnOrder(order));
  };
  return loading || loadinglearn ? (
    <div className="loading-course">
      <HashLoader
        color={"#1e1e2c"}
        loading={loading || loadinglearn}
        size={50}
      />
    </div>
  ) : error ? (
    <h1>{error}</h1>
  ) : (
    <div className="placeorder">
      <Helmet>
        <title>ORDER</title>
      </Helmet>
      <div className="informations-placeorder">
        <div className="shipping-placeorder">
          <h2>Shipping</h2>
          <p>
            <strong>Name: </strong>
            {order.user.name}
          </p>
          <p>
            <strong> Email: </strong>
            <a href={`mailto:${order.user.email}`}>{order.user.email}</a>
          </p>
          <p>
            <strong>Address: </strong>
            {order.shippingAddress.address}, {order.shippingAddress.city},{" "}
            {order.shippingAddress.cp}, {order.shippingAddress.country}
            {order.isLearn ? (
              <div className="paid">learned at {order.learnedAt}</div>
            ) : (
              <div className="notpaid">NOT Learned YET</div>
            )}
          </p>
        </div>
        <hr className="hr" />
        <div className="payment-placeorder">
          <h2>Payment Method</h2>
          <p>
            <strong>Method: </strong>
            {order.paymentMethod}
            {order.isPaid ? (
              <div className="paid">PAID AT {order.paidAt}</div>
            ) : (
              <div className="notpaid">NOT PAID YET</div>
            )}
          </p>
        </div>
        <hr className="hr" />
        <div>
          <h2>Order Items: </h2>
          {order.orderItems.length === 0 ? (
            <p>Your order is empty</p>
          ) : (
            <div className="orders-placeorder">
              {order.orderItems.map((item, index) => (
                <p key={index}>
                  <span className="color-name">
                    <Link to={`/course/${item.course}`}>{item.name}</Link>
                  </span>{" "}
                  <b>
                    {item.qty} x ${item.price} = ${item.qty * item.price}
                  </b>
                  <hr className="hr" />
                </p>
              ))}
            </div>
          )}
        </div>
      </div>
      <div className="your-courses">
        <div className="cart-summ">
          <h1>Order Summary</h1>

          <div className="calculs-placeorder">
            <h3>Items: </h3>
            <h3>Tax: </h3>
            <p>${order.taxPrice}</p>
            <h3>Total: </h3>
            <p>${order.totalPrice}</p>
          </div>
        </div>
        <div className="bottominfos">
          <h1 className="orderid">Order : {order._id}</h1>
          {!order.isPaid && (
            <>
              {loadingpay && (
                <div className="loading-course">
                  <HashLoader color={"#1e1e2c"} loading={loading} size={50} />
                </div>
              )}
              {!sdkReady ? (
                <div className="loading-course">
                  <HashLoader color={"#1e1e2c"} loading={loading} size={50} />
                </div>
              ) : (
                <div className="placebuttons">
                  <placeButton
                    className="buttonsp"
                    amount={order.totalPrice}
                    onSuccess={successpaymenthandler}
                  />
                </div>
              )}
            </>
          )}
          {userInfo &&
            userInfo.isAdmin &&
            order.isPaid &&
            !order.isLearn && (
              <Button
                height="40px"
                width="200px"
                size="lg"
                onClick={learnhandler}
                leftIcon={<IoMdDoneAll size="16" />}
                colorScheme="blue"
              >
                LEARNED
              </Button>
            )}
        </div>
      </div>
    </div>
  );
};

export default Order;
