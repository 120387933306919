import React, { useEffect } from "react";
import {
  DeleteCourse,
  listCourses,
  CreateCourse,
} from "../../actions/courseActions";
import { useDispatch, useSelector } from "react-redux";
import HashLoader from "react-spinners/HashLoader";
import { COURSE_CREATE_RESET } from "../../constants/courseConstants";
import "./courses.css";
import {
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Stack,
  Box,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { AiFillDelete, AiOutlineEdit } from "react-icons/ai";
import { CgAdd } from "react-icons/cg";
import { Helmet } from "react-helmet";

const Courses = ({ history, match }) => {
  const dispatch = useDispatch();
  const courseList = useSelector((state) => state.courseList);
  const { loading, error, courses } = courseList;

  const courseDelete = useSelector((state) => state.courseDelete);
  const {
    loading: loadingDelete,
    error: errorDelete,
    success: successDelete,
  } = courseDelete;

  const courseCreate = useSelector((state) => state.courseCreate);
  const {
    loading: loadingCreate,
    error: errorCreate,
    success: successCreate,
    course: createdcourse,
  } = courseCreate;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
    dispatch({ type: COURSE_CREATE_RESET });

    if (!userInfo.isAdmin) {
      history.push("/login");
    }
    if (successCreate) {
      history.push(`/admin/course/${createdcourse._id}/edit`);
    } else {
      dispatch(listCourses());
    }
  }, [
    dispatch,
    history,
    userInfo,
    successDelete,
    successCreate,
    createdcourse,
  ]);
  const deletehandler = (id) => {
    if (window.confirm("Are You Sure?")) {
      dispatch(DeleteCourse(id));
    }
  };

  const createcoursehandler = () => {
    dispatch(CreateCourse());
  };
  return (
    <div className="Users">
      <Helmet>
        <title>courses</title>
      </Helmet>
      <h1 className="titlepanel"> Courses :</h1>
      {loading || loadingDelete || loadingCreate ? (
        <div className="loading">
          <HashLoader
            color={"#1e1e2c"}
            loading={loading || loadingDelete || loadingCreate}
            size={40}
          />
        </div>
      ) : error || errorDelete || errorCreate ? (
        <h1>{error || errorDelete || errorCreate}</h1>
      ) : (
        <>
          <Button
            leftIcon={<CgAdd size="20" />}
            className="ADDBUTTON"
            colorScheme="blue"
            onClick={createcoursehandler}
          >
            ADD
          </Button>

          <Box overflowX="auto">
            <Table className="courseusers" variant="striped">
              <Thead>
                <Tr>
                  <Th w="10%">ID</Th>
                  <Th w="20%">Name</Th>
                  <Th w="20%">Price</Th>
                  <Th w="20%">Category</Th>
                  <Th w="10%"></Th>
                </Tr>
              </Thead>
              <Tbody>
                {courses.map((course) => (
                  <Tr key={course._id}>
                    <Td>{course._id}</Td>
                    <Td>{course.name}</Td>
                    <Td isNumeric>{course.price}</Td>
                    <Td>{course.category.join(" | ")}</Td>
                    <Td>
                      <Stack>
                        <Link to={`/admin/course/${course._id}/edit`}>
                          <Button
                            leftIcon={<AiOutlineEdit size="16" />}
                            colorScheme="blue"
                            size="xs"
                          >
                            EDIT
                          </Button>
                        </Link>
                        <Button
                          colorScheme="red"
                          leftIcon={<AiFillDelete size="16" />}
                          size="xs"
                          onClick={() => {
                            deletehandler(course._id);
                          }}
                        >
                          DELETE
                        </Button>
                      </Stack>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Box>
        </>
      )}
    </div>
  );
};

export default Courses;
