import { Image } from "@chakra-ui/image";
import React, { useRef, useState, useEffect } from "react";
import { Select } from "@chakra-ui/react";
import { VscChromeClose } from "react-icons/vsc";

import { addToCart, removeFromCart } from "../actions/cartActions";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";

const Courseoncart = ({ course }) => {
  const dispatch = useDispatch();

  const [qty, setqty] = useState(0);
  const select = useRef(null);
  useEffect(() => {
    console.log(course.images);
    return () => {};
  }, []);

  const optionvalue = () => {
    setqty(parseInt(select.current.value));
  };
  const removeFromCartHandler = (id) => {
    dispatch(removeFromCart(id));
  };
  return (
    <div className="coursecart">
      <div className="imagecart">
        <Image objectFit="cover" src={course.images[0]} />
      </div>
      <div>
        <Link to={`/course/${course.course}`}>
          <h2 className="coursename">{course.name}</h2>
        </Link>

        <h2 className="pricecourse">{course.price} Tnd</h2>
        <h2 className="sandh">This Course is by Patic</h2>
      </div>
      <div className="qtyoption">
        <Select
          ref={select}
          defaultValue={course.qty}
          onChange={(e) =>
            dispatch(addToCart(course.course, Number(e.target.value)))
          }
        >
          {[...Array(course.availability).keys()].map((x) => (
            <option value={x + 1}> {x + 1}</option>
          ))}
        </Select>
        <h2>
          {(qty === 0 ? course.qty * course.price : qty * course.price).toFixed(
            2
          )}
          Tnd
        </h2>
      </div>
      <VscChromeClose
        className="deletecart"
        size="26"
        onClick={() => removeFromCartHandler(course.course)}
      />
    </div>
  );
};

export default Courseoncart;
