import React, { useState } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import { Form, Image } from "react-bootstrap";
import avatarRegister from "./img/avatarRegister.svg";
import { Helmet } from "react-helmet";
import addUs from "./img/new.svg";
import wave from "./img/wavev.png";
import { Button, Input } from "@chakra-ui/react";
import axios from "../axios";

const RegisterScreen = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [isKid, setIsKid] = useState(false);

  const location = useLocation();
  const history = useHistory();

  const redirect = location.search ? location.search.split("=")[1] : "/";

  const submitHandler = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setMessage("Passwords do not match");
    } else {
      setMessage(null);
      try {
        await axios.post("/api/users", {
          name,
          email,
          password,
          isKid,
        });
        setSuccessMessage(
          "Registration successful. Please check your email to verify your account."
        );
        // Redirect to the login page after successful registration
        setTimeout(() => {
          history.push(`/login?redirect=${redirect}`);
        }, 2000);
      } catch (error) {
        setMessage(error.response.data.message || "An error occurred");
      }
    }
  };

  return (
    <div className="registerSc">
      <Helmet>
        <title>Register</title>
      </Helmet>
      <Image className="wave" src={wave} />
      <div className="containera">
        <div className="imga">
          <Image src={addUs} />
        </div>
        <div className="login-content">
          <form onSubmit={submitHandler}>
            <Image src={avatarRegister} />
            {message && <h4 className="error-message">{message}</h4>}
            {successMessage && (
              <h4 className="success-message">{successMessage}</h4>
            )}
            <div className="input-div zz">
              <div className="i">
                <i className="fas fa-user"></i>
              </div>
              <div className="div">
                <input
                  type="text"
                  value={name}
                  className="inputa"
                  placeholder="Enter name"
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
            </div>
            <div className="input-div one">
              <div className="i">
                <i className="fas fa-envelope"></i>
              </div>
              <div className="div">
                <input
                  type="text"
                  value={email}
                  className="inputa"
                  placeholder="Enter email"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
            </div>
            <div className="input-div pass">
              <div className="i">
                <i className="fas fa-lock"></i>
              </div>
              <div className="div">
                <input
                  type="password"
                  value={password}
                  className="inputa"
                  placeholder="Enter password"
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
            </div>
            <div className="input-div passconf">
              <div className="i">
                <i className="fas fa-lock"></i>
              </div>
              <div className="div">
                <input
                  type="password"
                  value={confirmPassword}
                  className="inputa"
                  placeholder="Confirm password"
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
              </div>
            </div>
            <div className="input-div kid-toggle">
              <div className="i">
                <i className="fas fa-child"></i>
              </div>
              <div className="div">
                <Form.Check
                  type="checkbox"
                  label="I am a kid"
                  checked={isKid}
                  onChange={(e) => setIsKid(e.target.checked)}
                />
              </div>
            </div>
            <input type="submit" className="btna2" value="Sign up" />
            <br />
            Have an Account?{" "}
            <Link to={redirect ? `/login?redirect=${redirect}` : "/login"}>
              Login
            </Link>
          </form>
        </div>
      </div>
    </div>
  );
};

export default RegisterScreen;
