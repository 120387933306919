import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Form, Image } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { Helmet } from 'react-helmet';
import addUs from './img/new.svg'
import wave from './img/wavev.png'
import { forgotPassword } from '../actions/userActions' // Replace with your actual action

import { Button, Input } from "@chakra-ui/react"

const ForgotPasswordScreen = ({ history }) => {
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState(null)

  const dispatch = useDispatch()

  const userForgotPassword = useSelector(state => state.userForgotPassword)
  const { error, success } = userForgotPassword

  const submitHandler = (e) => {
    e.preventDefault()
    if (email) {
      dispatch(forgotPassword(email))
    } else {
      setMessage('Please enter your email')
    }
  }

  const handleFocus = (e) => {
    const parent = e.target.parentNode.parentNode;
    parent.classList.add("focus");
  }

  const handleBlur = (e) => {
    const parent = e.target.parentNode.parentNode;
    if (e.target.value === "") {
      parent.classList.remove("focus");
    }
  }

  return (
    <div className="forgotPasswordSc">
      <Helmet>
        <title>Forgot Password</title>
      </Helmet>
      <Image className="wave" src={wave} />

      <div className="containera">
        <div className="imga">
          <Image src={addUs} />
        </div>
        <div className="login-content">
          <form onSubmit={submitHandler}>
            <Image src={'./img/avatarForgotPassword.svg'} alt="Avatar" /> {/* Replace with a relevant image */}
            {error && <h4>{error}</h4>}
            {message && <h4>{message}</h4>}
            {success && <h4>Check your email for further instructions</h4>}

            <div className="input-div one">
              <div className="i">
                <i className="fas fa-envelope"></i>
              </div>
              <div className="div">
                <input
                  type="text"
                  value={email}
                  className="inputa"
                  placeholder="Enter your email"
                  onChange={(e) => setEmail(e.target.value)}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  aria-label="Email"
                />
              </div>
            </div>

            <input type="submit" className="btna2" value="Reset Password" />
            <br />
            Remembered your password? {' '}
            <Link to={'/login'}>Login</Link>
          </form>
        </div>
      </div>
    </div>
  )
}

export default ForgotPasswordScreen