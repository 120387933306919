import React, { useState, useEffect } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Image } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import addUs from './img/new.svg';
import wave from './img/wavev.png';
import axios from '../axios';

const ResetPasswordScreen = () => {
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [message, setMessage] = useState('');
    const [validToken, setValidToken] = useState(false);
    const [loading, setLoading] = useState(true); // New state for loading
    const location = useLocation();
    const history = useHistory();

    const token = new URLSearchParams(location.search).get('token');

    useEffect(() => {
        const validateToken = async () => {
            if (!token) {
                setMessage('Invalid or missing token.');
                setLoading(false);
                return;
            }
            try {
                await axios.get(`/api/users/validate-reset-token/${token}`);
                setValidToken(true);
            } catch (error) {
                setMessage('Invalid or expired token.');
            }
            setLoading(false);
        };

        validateToken();
    }, [token]);

    const submitHandler = async (e) => {
        e.preventDefault();

        if (newPassword !== confirmPassword) {
            setMessage('Passwords do not match');
            return;
        }

        try {
            const { data } = await axios.post(`/api/users/reset-password/${token}`, { newPassword });
            setMessage(data.message);
            history.push('/login');
        } catch (error) {
            setMessage('Error resetting password. Please try again.');
        }
    };

    const handleFocus = (e) => {
        const parent = e.target.closest('.input-div');
        parent.classList.add('focus');
    };

    const handleBlur = (e) => {
        const parent = e.target.closest('.input-div');
        if (e.target.value === '') {
            parent.classList.remove('focus');
        }
    };

    if (loading) {
        return <div>Loading...</div>; // Show loading state
    }

    if (!validToken) {
        return <div>{message}</div>; // Show error message
    }

    return (
        <div className="registerSc">
            <Helmet>
                <title>Reset Password</title>
            </Helmet>
            <Image className="wave" src={wave} />

            <div className="containera">
                <div className="imga">
                    <Image src={addUs} />
                </div>
                <div className="login-content">
                    <form onSubmit={submitHandler}>
                        <Image src={'./img/avatarResetPassword.svg'} alt="Avatar" />
                        {message && <h4>{message}</h4>}

                        <div className="input-div pass">
                            <div className="i">
                                <i className="fas fa-lock"></i>
                            </div>
                            <div className="div">
                                <input
                                    type="password"
                                    value={newPassword}
                                    className="inputa"
                                    placeholder="Enter new password"
                                    onChange={(e) => setNewPassword(e.target.value)}
                                    onFocus={handleFocus}
                                    onBlur={handleBlur}
                                    aria-label="New Password"
                                />
                            </div>
                        </div>

                        <div className="input-div passconf">
                            <div className="i">
                                <i className="fas fa-lock"></i>
                            </div>
                            <div className="div">
                                <input
                                    type="password"
                                    value={confirmPassword}
                                    className="inputa"
                                    placeholder="Confirm new password"
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                    onFocus={handleFocus}
                                    onBlur={handleBlur}
                                    aria-label="Confirm Password"
                                />
                            </div>
                        </div>

                        <input type="submit" className="btna2" value="Reset Password" />
                        <br />
                        Remembered your password?{' '}
                        <Link to={'/login'}>Login</Link>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default ResetPasswordScreen;
