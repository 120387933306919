import { React, useState, useEffect } from "react";
import { HiOutlineShoppingCart, HiShoppingCart } from "react-icons/hi";

import { Image } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import Rating from "./Rating";
import { addToCart } from "../actions/cartActions";
import { useDispatch, useSelector } from "react-redux";
const CardCourse = ({ course }) => {
  const [showbtn, setShowbtn] = useState(false);
  const [Incart, setIncart] = useState(false);
  const dispatch = useDispatch();
  const Cart = useSelector((state) => state.cart);
  const { cartItems } = Cart;
  useEffect(() => {
    const isincart = cartItems.find((x) => x.course === course._id);
    if (isincart) {
      setIncart(true);
    }
    return () => {};
  });
  const addcart = () => {
    setIncart(true);
    dispatch(addToCart(course._id, 1));
  };

  return (
    <>
      <div
        className="cardCourse"
        onMouseOver={() => {
          setShowbtn(true);
        }}
        onMouseLeave={() => {
          
          setShowbtn(false);
        }}
      >
        <div className="imgDiv">
          <Image
            className="imgCourse"
            boxSize="350px"
            objectFit="cover"
            src={course.images[0]}
          />
        </div>
        <div className="bottomcard">
          <Link to={`/course/${course._id}`} exact>
            <span>{course.name}</span>
          </Link>
          {Incart ? (
            <HiShoppingCart className="iconFav" size="26" />
          ) : (
            <HiOutlineShoppingCart
              className="iconFav"
              color="#999"
              size="26"
              onClick={addcart}
            />
          )}

          <div className="coursepricecard"> {`${course.price} Tnd`}</div>
          <div className="Rating">
            <Rating
              value={course.rating}
              text={`${course.numReviews} reviews`}
            />
          </div>
        </div>

        <Link to={`/course/${course._id}`} exact>
          <button
            className={showbtn ? "QuickView QuickViewActive" : "QuickView"}
          >
            {" "}
            View Details
          </button>
        </Link>
      </div>
    </>
  );
};

export default CardCourse;
