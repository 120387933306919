import React, { useRef, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Route, Link, NavLink } from "react-router-dom";
import {
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";

// Correct icon imports
import { RiShoppingCart2Line } from "react-icons/ri";
import {
  IoCloseOutline,
  IoLogOutOutline,
} from "react-icons/io5";
import { MdSearch, MdKeyboardArrowRight } from "react-icons/md";
import { BsArrowRightShort } from "react-icons/bs";
import { CgProfile } from "react-icons/cg";
import { IoMdArrowDropdown } from "react-icons/io"; // Correct package for IoMdArrowDropdown

import { logout } from "../actions/userActions";
import Searchnav from "./Searchnav";

const Nav = ({ history }) => {
  const [incart, setInCart] = useState(0);
  const cart = useSelector((state) => state.cart);
  const { cartItems } = cart;
  const [nav, setNav] = useState(false);
  const navRef = useRef(null);

  // Search
  const searchRef = useRef(null);
  const [showSearchIcon, setShowSearchIcon] = useState(false);

  // Burger
  const burgerIconRef = useRef(null);
  const navLinksRef = useRef(null);
  const rightItemsRef = useRef(null);

  // Signin
  const [signin, setSignin] = useState(false);

  const onSearchToggle = () => {
    setShowSearchIcon(!showSearchIcon);
    searchRef.current.classList.toggle("searchActive");
    searchRef.current.style.animation = "moving 0.3s ease both 0.3s";
  };

  const onBurgerToggle = () => {
    const links = document.querySelectorAll(".navLinks li");
    navLinksRef.current.classList.toggle("burgerActive");
    rightItemsRef.current.classList.toggle("burgerActive");

    // Animate Links
    links.forEach((link, index) => {
      if (link.style.animation) {
        link.style.animation = "";
        rightItemsRef.current.style.animation = "";
      } else {
        link.style.animation = `moving 0.5s ease forwards ${index / 5}s`;
        rightItemsRef.current.style.animation = `moving 0.5s ease forwards ${
          index / 5
        }s`;
      }
    });

    // Burger Animation
    burgerIconRef.current.classList.toggle("toggle");
  };

  const onScrollChange = () => {
    if (window.scrollY >= 60) {
      setNav(true);
    } else {
      setNav(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", onScrollChange);

    return () => {
      window.removeEventListener("scroll", onScrollChange);
    };
  }, []);

  useEffect(() => {
    setInCart(cartItems.length);
  }, [cartItems]);

  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const logoutHandler = () => {
    dispatch(logout());
  };

  return (
    <nav ref={navRef} className={`nav ${nav ? "active" : ""}`}>
      <div className="logo">
        <Link to="/">Patic</Link>
      </div>
      <ul className="navLinks" ref={navLinksRef}>
        <NavLink to="/" exact activeClassName="activlink">
          <li>Home</li>
        </NavLink>
        <NavLink to="/courses" activeClassName="activlink">
          <li>Courses</li>
        </NavLink>
        <NavLink to={userInfo && userInfo.isKid ? "/notfound" : "/contactus"} activeClassName="activlink">
          <li>{userInfo && userInfo.isKid ? "Quizz" : "Contact us"}</li>
        </NavLink>
        <NavLink to="/about" activeClassName="activlink">
          <li>About</li>
        </NavLink>
      </ul>
      <div className="burger" ref={burgerIconRef} onClick={onBurgerToggle}>
        <div className="line1"></div>
        <div className="line2"></div>
        <div className="line3"></div>
      </div>
      <div className="rightComp" ref={rightItemsRef}>
        <div ref={searchRef} className="search">
          <Route render={({ history }) => <Searchnav history={history} />} />
        </div>

        {!showSearchIcon && (
          <MdSearch className="iconSearch" size="26" onClick={onSearchToggle} />
        )}
        <Link to="/cart">
          <RiShoppingCart2Line className="iconCart" size="26" />
          {userInfo && !userInfo.isAdmin && (
            <div className="dotcart">{incart}</div>
          )}
        </Link>

        {userInfo ? (
          <div className="ic_sett_dis">
            <Link to="/profile">
              <CgProfile size="25" className="settingIcon" />
            </Link>
            <IoLogOutOutline
              size="28"
              className="displayIcon"
              onClick={logoutHandler}
            />
          </div>
        ) : (
          <Link to="/login">
            <div
              className="signin"
              onMouseOver={() => setSignin(true)}
              onMouseOut={() => setSignin(false)}
            >
              Sign in
              {!signin ? (
                <BsArrowRightShort size="25" />
              ) : (
                <MdKeyboardArrowRight size="25" />
              )}
            </div>
          </Link>
        )}
        {userInfo && userInfo.isAdmin && (
          <Menu>
            <MenuButton as={Button} rightIcon={<IoMdArrowDropdown />}>
              Admin
            </MenuButton>
            <MenuList>
              <MenuItem>
                <Link to="/admin/userlist">Users</Link>
              </MenuItem>
              <MenuItem>
                <Link to="/admin/courselist">Courses</Link>
              </MenuItem>
              <MenuItem>
                <Link to="/admin/orderlist">Orders</Link>
              </MenuItem>
            </MenuList>
          </Menu>
        )}
      </div>
    </nav>
  );
};

export default Nav;
