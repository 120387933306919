import axios from "../../axios";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { listCourseDetails, UpdateCourse } from "../../actions/courseActions";
import HashLoader from "react-spinners/HashLoader";
import { Input, InputGroup } from "@chakra-ui/input";
import { Helmet } from "react-helmet";

import { Box, Checkbox, Stack, Textarea, VStack } from "@chakra-ui/react";
import { COURSE_UPDATE_RESET } from "../../constants/courseConstants";
import "./Editcourse.css";
const Editcourse = ({ match, history }) => {
  const courseId = match.params.id;
  const [name, setName] = useState("");
  const [description, setdescription] = useState("");
  const [price, setprice] = useState(0);
  const [availability, setavailability] = useState(0);
  const [Url1, setUrl1] = useState("");
  const [Url2, setUrl2] = useState("");
  const [Url3, setUrl3] = useState("");

  const [Images, setImages] = useState([]);
  const [sizes, setsizes] = useState([]);
  const [category, setcategory] = useState([]);
  const [Englishselected, setEnglishselected] = useState(false);
  const [Francaisselected, setFrancaisselected] = useState(false);
  const [Artselected, setArtselected] = useState(false);
  const [Programmingselected, setProgrammingselected] = useState(false);
  const [Crewselected, setCrewselected] = useState(false);
  const [Softskillsselected, setSoftskillsselected] = useState(false);
  const [otherselected, setotherselected] = useState(false);
  const [ProgrammingKidsSelected, setProgrammingKidsSelected] = useState(false);
  const [LanguageKidsSelected, setLanguageKidsSelected] = useState(false);
  const [ArtKidsSelected, setArtKidsSelected] = useState(false);

  const [Sselected, setSselected] = useState(false);
  const [Mselected, setMselected] = useState(false);
  const [Lselected, setLselected] = useState(false);
  const [kidsselected, setkidsselected] = useState(false);

  const [message, setMessage] = useState(null);

  const dispatch = useDispatch();

  const courseDetails = useSelector((state) => state.courseDetails);

  const { loading, error, course } = courseDetails;

  const courseUpdate = useSelector((state) => state.courseUpdate);

  const {
    loading: lodingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = courseUpdate;

  useEffect(() => {
    if (successUpdate) {
      dispatch({ type: COURSE_UPDATE_RESET });
      history.push("/admin/courselist");
    } else {
      if (!course.name || course._id !== courseId) {
        dispatch(listCourseDetails(courseId));
      } else {
        setName(course.name);
        setprice(course.price);
        setdescription(course.description);
        setUrl1(course.images[0]);
        setUrl2(course.images[1]);
        setUrl3(course.images[2]);
        setcategory(course.category);
        /*setsizes(course.sizes);*/
        setavailability(course.availability);
              // Set the state for Kids
        setProgrammingKidsSelected(category.includes("Programming Kids"));
        setLanguageKidsSelected(category.includes("Language Kids"));
        setArtKidsSelected(category.includes("Art Kids"));
        setSoftskillsselected(category.includes("Soft skills"));
              // Set the state for Adults
        setArtselected(category.includes("ART"));
        setotherselected(category.includes("other"));
        setCrewselected(category.includes("Cabin Crew"));
        setEnglishselected(category.includes("English"));
        setFrancaisselected(category.includes("Francais"));
        setProgrammingselected(category.includes("Programming"));
        /*setSselected(sizes.includes("S"));
        setLselected(sizes.includes("L"));
        setMselected(sizes.includes("M"));
        setkidsselected(sizes.includes("kids"));*/
      }
    }

    return () => {};
  }, [dispatch, courseId, history, course, category, successUpdate]);

  const submitHandler = (e) => {
    Images.push(Url1);
    Images.push(Url2);
    Images.push(Url3);

    e.preventDefault();
    dispatch(
      UpdateCourse({
        _id: courseId,
        name,
        price,
        Images,
        category,
        sizes,
        availability,
        description,
      })
    );
  };
  const checkboxhandler = (D) => {
    let index = sizes.indexOf(D);
    if (index > -1) {
      sizes.splice(index, 1);
      console.log(sizes);
    } else {
      sizes.push(D);
      console.log(sizes);
    }
  };

  const checkboxhandlercg = (D) => {
    let index = category.indexOf(D);
    if (index > -1) {
      category.splice(index, 1);
    } else {
      category.push(D);
    }
  };

  return (
    <div className="Edituser">
      <Helmet>
        <title>Edit Course</title>
      </Helmet>
      {error && <h4>{error}</h4>}
      {/* {successUpdate && <h4>Profile Updated</h4>} */}
      {loading || lodingUpdate ? (
        <div className="loading">
          <HashLoader color={"#1e1e2c"} loading={lodingUpdate} size={40} />
        </div>
      ) : errorUpdate ? (
        <h4>{errorUpdate}</h4>
      ) : (
        <div>
          <h4 className="Edittitle">Edit Course :</h4>
          <div className="formedit">
            <form onSubmit={submitHandler}>
              <div>
                <div className="input-div zz">
                  Name :
                  <div className="div">
                    <InputGroup>
                      <Input
                        type="text"
                        value={name}
                        placeholder="Enter name"
                        onChange={(e) => setName(e.target.value)}
                      />
                    </InputGroup>
                  </div>
                </div>

                <div className="input-div one">
                  Price :
                  <div className="div">
                    <InputGroup>
                      <Input
                        type="text"
                        value={price}
                        placeholder="Enter price"
                        onChange={(e) => setprice(e.target.value)}
                      />
                    </InputGroup>
                  </div>
                </div>
                <div className="input-div one">
                  availability :
                  <div className="div">
                    <InputGroup>
                      <Input
                        type="text"
                        value={availability}
                        placeholder="Enter price"
                        onChange={(e) => setavailability(e.target.value)}
                      />
                    </InputGroup>
                  </div>
                </div>
                <div className="input-div one">
                  Description/Category
                  <div className="div">
                    <Stack direction="column" spacing={4}>
                      <InputGroup>
                        <Textarea
                          size="sm"
                          value={description}
                          placeholder="Enter price"
                          onChange={(e) => setdescription(e.target.value)}
                        />
                      </InputGroup>
                      <Stack direction="row">
                        <Checkbox
                          onChange={() => {
                            checkboxhandlercg("English");
                            setEnglishselected(!Englishselected);
                          }}
                          isChecked={Englishselected}
                        >
                          English{" "}
                        </Checkbox>
                        <Checkbox
                          onChange={() => {
                            checkboxhandlercg("Francais");
                            setFrancaisselected(!Programmingselected);
                          }}
                          isChecked={Francaisselected}
                        >
                          F{" "}
                        </Checkbox>
                        <Checkbox
                          onChange={() => {
                            checkboxhandlercg("Art");
                            setArtselected(!Artselected);
                          }}
                          isChecked={Artselected}
                        >
                          Art{" "}
                        </Checkbox>
                        <Checkbox
                          onChange={() => {
                            checkboxhandlercg("Programming");
                            setProgrammingselected(!Programmingselected);
                          }}
                          isChecked={Programmingselected}
                        >
                          Programming{" "}
                        </Checkbox>
                        <Checkbox
                          onChange={() => {
                            checkboxhandlercg("Cabin Crew");
                            setCrewselected(!Crewselected);
                          }}
                          isChecked={Crewselected}
                        >
                          Crew{" "}
                        </Checkbox>
                        <Checkbox
                          onChange={() => {
                            checkboxhandlercg("Soft skills");
                            setSoftskillsselected(!Softskillsselected);
                          }}
                          isChecked={Softskillsselected}
                        >
                          Softskills{" "}
                        </Checkbox>
                        <Checkbox
                          onChange={() => {
                            checkboxhandlercg("other");
                            setotherselected(!otherselected);
                          }}
                          isChecked={otherselected}
                        >
                          other{" "}
                        </Checkbox>
                        <Checkbox
                        onChange={() => {
                          checkboxhandlercg("Programming Kids");
                          setProgrammingKidsSelected(!ProgrammingKidsSelected);
                        }}
                        isChecked={ProgrammingKidsSelected}
                      >
                        Programming-Kids{" "}
                      </Checkbox>
                      <Checkbox
                        onChange={() => {
                          checkboxhandlercg("Language Kids");
                          setLanguageKidsSelected(!LanguageKidsSelected);
                        }}
                        isChecked={LanguageKidsSelected}
                      >
                        Language-Kids{" "}
                      </Checkbox>
                      </Stack>
                    </Stack>
                  </div>
                </div>

                <div className="input-div pass">
                  <div className="div"></div>
                </div>

                <div className="input-div pass">
                  Sizes:
                  <div className="div">
                    <Stack direction="row">
                      <Checkbox
                        onChange={() => {
                          checkboxhandler("S");
                          setSselected(!Sselected);
                        }}
                        isChecked={Sselected}
                      >
                        S{" "}
                      </Checkbox>
                      <Checkbox
                        onChange={() => {
                          checkboxhandler("M");
                          setMselected(!Mselected);
                        }}
                        isChecked={Mselected}
                      >
                        M{" "}
                      </Checkbox>
                      <Checkbox
                        onChange={() => {
                          checkboxhandler("L");
                          setLselected(!Lselected);
                        }}
                        isChecked={Lselected}
                      >
                        L{" "}
                      </Checkbox>
                      <Checkbox
                        onChange={() => {
                          checkboxhandler("kids");
                          setkidsselected(!kidsselected);
                        }}
                        isChecked={kidsselected}
                      >
                        kids{" "}
                      </Checkbox>
                    </Stack>
                  </div>
                </div>
                <div className="input-div pass">
                  Urls for images:
                  <div className="div urls">
                    <Box>
                      <Stack direction="column">
                        <Input
                          type="text"
                          value={Url1}
                          onChange={(e) => {
                            setUrl1(e.target.value);
                          }}
                        />
                        <Input
                          type="text"
                          value={Url2}
                          onChange={(e) => {
                            setUrl2(e.target.value);
                          }}
                        />
                        <Input
                          type="text"
                          value={Url3}
                          onChange={(e) => {
                            setUrl3(e.target.value);
                          }}
                        />
                      </Stack>
                    </Box>
                    {/* <Input type= 'text' value={category} onChange = {(e)=>{setcategory((e.target.value).split(' ')) ; }}/> */}
                  </div>
                </div>
                {message && <h4 className="Message">{message}</h4>}
                <input
                  type="submit"
                  className="btna2 postionbtnupdate"
                  value="Update"
                />
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default Editcourse;
