import React, { useState, useEffect } from "react";
import Slider from "../components/Slider";
import Cardscg from "../components/Cardscg";
import CgDiv from "../components/CgDiv";
import CoursesC from "../components/CoursesC";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

const Home = () => {
  return (
    <>
      <Helmet>
        <title>Patic</title>
      </Helmet>
      <div>
        <Slider />
        <div className="cards">
          <Cardscg title="Programming" />
          <Cardscg title="Language" />
          <Cardscg title="Art" />
        </div>
        <CgDiv />
        <CoursesC />
      </div>
    </>
  );
};

export default Home;
