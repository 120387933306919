import React from 'react';
import { Link } from 'react-router-dom';

const CardscgKids = ({ title }) => {
  return (
    <div className="cardscg kids-card">
      <h1>{title}</h1>
      <Link to={`/courseskids/?cg=${title}`} className="learnNowcg">Learn now</Link>
    </div>
  );
}

export default CardscgKids;
