import { React, useEffect, useState } from "react";
import {
  IoIosArrowDown,
  IoIosArrowForward,
  IoIosArrowBack,
} from "react-icons/io";

import { Link } from "react-router-dom";
import LearnNowBtn from "./learnNowBtn";
const Slider = () => {
  const SliderData = [
    {
      title: "Latest Offerings",
      subtitle: "Explore Our Latest Course Selections",
    },
    {
      title: "Special Discounts",
      subtitle: "Get Our Courses at Reduced Prices",
    },
    {
      title: "Unlock Creativity",
      subtitle: "Experience Freedom with Our Art Therapy Programs",
    },
    {
      title: "Return to Learning",
      subtitle: "Prepare Your Children for a Successful School Year",
    },
  ];
  const [current, setCurrent] = useState(0);
  const length = SliderData.length;
  const [auto, setauto] = useState(true);
  const intervaltime = 6000;
  let slideinterval;
  const nextslide = () => {
    clearInterval(slideinterval);
    slideinterval = setInterval(nextslide, intervaltime);
    setTimeout(() => setCurrent(current === length - 1 ? 0 : current + 1));
  };
  const prevslide = () => {
    clearInterval(slideinterval);
    slideinterval = setInterval(nextslide, intervaltime);
    setTimeout(() => setCurrent(current === 0 ? length - 1 : current - 1));
  };
  useEffect(() => {
    setauto(true);
    if (auto) {
      slideinterval = setInterval(nextslide, intervaltime);
    }
    return () => {
      setauto(false);
      clearInterval(slideinterval);
    };
  });

  return (
    <div className="slider">
      {SliderData.map((slide, index) => {
        return (
          <div
            key={index}
            className={index === current ? "slide current" : "slide"}
          >
            <h1 className="titleslider sm:text-[1rem] md:text-[0.5rem] lg:text-[0.25rem]">
              {slide.title}
            </h1>
            <h3 className="subtitleslider sm:text-[1rem] md:text-[0.5rem] lg:text-[0.25rem]">
              {slide.subtitle}
            </h3>
            <div className="content">
              {" "}
              <Link to="/courses">
                {" "}
                <LearnNowBtn />
              </Link>{" "}
            </div>
          </div>
        );
      })}
      <IoIosArrowForward className="next" size="40" onClick={nextslide} />
      <IoIosArrowBack className="prev" size="40" onClick={prevslide} />
    </div>
  );
};

export default Slider;
