import React from "react";
import { FiFacebook } from "react-icons/fi";
import { AiOutlineHeart, AiOutlineInstagram } from "react-icons/ai";
import { IoLogoYoutube } from "react-icons/io5";
import { Input, Stack } from "@chakra-ui/react";
import "./footercss.css";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="footerCmp">
      <footer>
      <div className="footerLogo"></div> {/* Image is handled in CSS */}
        <div className="footerCategorie">
          <h1>Categories</h1>
          <ul>
            <li>
              <Link to="/courses/?cg=Programming">Language</Link>
            </li>
            <li>
              <Link to="/courses/?cg=Francais">Language</Link>
            </li>
            <li>
              <Link to="/courses/?cg=Art">Art</Link>
            </li>
            <li>
              <Link to="/courses/?cg=other">Other</Link>
            </li>
          </ul>
        </div>

        <div className="footerGetInTouch">
          <h1>Got Questions?</h1>
          <ul>
            <p>
              Visit us at El Kadhi Ibn Edriss El Khaldouni
              Next to Al Bader Mosque, Manouba, Tunisia
            </p>
            <p>📞 Phone: (+216) 22 894 896</p>
            <p>✉️ Email: contact@patic.net</p>
            <li className="footerIcons">
              <FiFacebook size="25" />
            </li>
            <li className="footerIcons">
              <AiOutlineInstagram size="25" />
            </li>
            <li className="footerIcons">
              <IoLogoYoutube size="25" />
            </li>
          </ul>
        </div>

        <div className="footerNews">
          <h1>Newsletter</h1>
          <ul>
            <li>
              <Stack spacing={3}>
                <Input
                  variant="flushed"
                  placeholder="email@example.com"
                  size="10"
                  width="200px"
                />
              </Stack>
            </li>
            <li>
              <button className="footerBtn">Subscribe</button>
            </li>
          </ul>
        </div>

        {/* <div className="creditsIcons">
          <ul>
            <li>
              <img src="https://i.imgur.com/AHCoUZO.png" cl assName="img1" />
            </li>
            <li>
              <img
                src="https://i.postimg.cc/vZH4p27P/Untitled.jpg"
                className="img2"
              />
            </li>
            <li>
              <img src="https://i.imgur.com/l8OAGyo.png" className="img3" />
            </li>
            <li>
              <img
                src="https://i.postimg.cc/zvrBpxXF/unnamed.png"
                className="img2"
              />
            </li>
          </ul>
        </div> */}

        <div className="paragraphFooter">
          <p>
            Copyright ©2024 All rights reserved | This template is made with ♡
            by Developers of ESSAI
          </p>
          <Link to="">Ismail Khlifi</Link>
          <Link to=""></Link>
          <a href="https://www.linkedin.com/in/yassine-ben-zekri-72aa6b199/">Yassine Ben Zekri</a>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
